import {IPlatformAPI} from '@wix/yoshi-flow-editor'
import type {BiParams, CreateBiMiddlewareParams, EventMap} from './interfaces'

export const createUouBiMiddlewareWithBiParams = (params: BiParams, events: EventMap) => {
  if (params.wixCodeApi.window.viewMode === 'Editor') {
    return noop
  }
  return createMiddleware({
    bi: UoUBiLogger(params),
    eventMap: events.eventMap,
    biParams: params,
  })
}

export const createUsersBiMiddlewareWithBiParams = (params: BiParams, events: EventMap) =>
  createMiddleware({
    bi: userBiLogger(params),
    eventMap: events.eventMap,
    biParams: params,
  })

const noop = () => next => action => next(action)

const createMiddleware = ({bi, eventMap, biParams}: CreateBiMiddlewareParams) => {
  const log = (action, store) => {
    const transformEvent = eventMap[action.type]

    if (transformEvent) {
      const biEvent = transformEvent(store.getState(), action, biParams)
      bi.log(biEvent, {endpoint: eventMap.endpoint})
    }
  }
  return store => next => action => {
    if (action) {
      log(action, store)
    }

    return next(action)
  }
}

const UoUBiLogger = ({wixCodeApi, platformAPIs, compId}: BiParams) => {
  const visitorId = platformAPIs.bi.visitorId || platformAPIs.bi.ownerId // ownerId when BI events fires in editor
  return getBiLogger(platformAPIs)
    .updateDefaults({
      comp_id: compId,
      _viewMode: wixCodeApi.window.viewMode.toLowerCase(),
      visitor_id: visitorId,
    })
    .withUoUContext({
      msid: platformAPIs.bi.metaSiteId,
      visitorId,
      siteMemberId: platformAPIs.bi.siteMemberId,
    })
    .logger()
}

const userBiLogger = ({compId, platformAPIs, appParams}: BiParams) =>
  getBiLogger(platformAPIs)
    .updateDefaults({
      src: 68,
      msid: platformAPIs.bi.metaSiteId,
      instance_id: appParams.instanceId,
      comp_id: compId,
    })
    .logger()

const getBiLogger = (platformAPIs: IPlatformAPI) => {
  const logger: any = platformAPIs.biLoggerFactory()

  return logger.factory ? logger.factory() : logger
}
