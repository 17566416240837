import {
  IFRAME_BASE_URL,
  NEW_DASHBOARD_PATHS,
  getBillingUrl,
  EVENTS_APP_ID,
  isInitiallyTicketed,
} from '@wix/wix-events-commons-statics'
import {TFunction} from '@wix/yoshi-flow-editor'
import {boLoadingStart101, pagesModalOpened, createManageEventClick, upgradeClick} from '@wix/bi-logger-events-users/v2'
import {UPDATE_SITE_SETTINGS} from '../../../../commons/actions/site-settings'
import {getActiveEvent, getEventById, isDemoEvents} from '../selectors/events'
import {createAsyncAction} from '../services/redux-toolkit'
import {getGeneralFields} from '../selectors/bi'
import {SettingsUpdatedActionType} from '../../Widget/actions/sdk'

export const OPEN_REGIONAL_SETTINGS_HELP = 'OPEN_REGIONAL_SETTINGS_HELP'
export const OPEN_BILLING_PAGE = 'OPEN_BILLING_PAGE'

const PAGES_MODAL_URL = `${IFRAME_BASE_URL}/html/widget/settings/modal/pages/widget`

export enum PAGES_MODAL_ORIGIN {
  TAB = 'main_page_tab',
  FOOTER = 'main_page_footer',
}

export const navigateToPage = createAsyncAction<void, string>('NAVIGATE_TO_PAGE', async (slug, {extra: {Wix}}) => {
  Wix.Data.Public.set('NAVIGATE_TO_PAGE', slug, {scope: 'APP'}, undefined, undefined)
  Wix.Utils.navigateToSection(
    {appDefinitionId: EVENTS_APP_ID, sectionId: 'events', state: slug, queryParams: ''},
    () => {},
  )
})

interface OpenFirstTimeCreateEventParams {
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openFirstTimeCreateEvent = createAsyncAction<void, OpenFirstTimeCreateEventParams>(
  'OPEN_FIRST_TIME_CREATE_EVENT',
  async ({multiEventWidget, calendar, recurringFilter}, {dispatch, extra: {serverApi}}) => {
    dispatch(openCreateEvent({multiEventWidget, calendar, recurringFilter}))
    const payload = await serverApi.updateSiteSettings({firstTimeCompleted: true})
    dispatch({type: UPDATE_SITE_SETTINGS.SUCCESS, payload})
  },
)

interface OpenCreateEventParams {
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openCreateEvent = createAsyncAction<void, OpenCreateEventParams>(
  'OPEN_CREATE_EVENT',
  async ({multiEventWidget, calendar, recurringFilter}, {dispatch, getState, extra: {bi, Wix}}) => {
    bi.report(
      createManageEventClick({
        instance_id: Wix.Utils.getInstanceId(),
        tab_name: 'main',
        button_name: 'create_event',
        ...getGeneralFields({state: getState(), multiEventWidget, calendar, recurringFilter}),
      }),
    )
    dispatch(openDashboard(NEW_DASHBOARD_PATHS.NEW_EVENT))
  },
)

export const openEventDetails = createAsyncAction<void, string>('OPEN_EVENT_DETAILS', async (eventId, {dispatch}) => {
  const path = eventId ? NEW_DASHBOARD_PATHS.EVENT_DETAILS(eventId) : NEW_DASHBOARD_PATHS.NEW_EVENT
  dispatch(openDashboard(path))
})

export const openMyEvents = () => openDashboard(NEW_DASHBOARD_PATHS.MY_EVENTS)

interface OpenManageEventParams {
  eventId?: string
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openManageEvent = createAsyncAction<void, OpenManageEventParams>(
  'OPEN_MANAGE_EVENT',
  async ({eventId, multiEventWidget, calendar, recurringFilter}, {getState, dispatch, extra: {bi, Wix}}) => {
    const state = getState()

    bi.report(
      createManageEventClick({
        instance_id: Wix.Utils.getInstanceId(),
        tab_name: 'main',
        button_name: 'manage_event',
        ...getGeneralFields({state: getState(), multiEventWidget, calendar, recurringFilter}),
      }),
    )

    if (multiEventWidget && !isDemoEvents(state)) {
      dispatch(openMyEvents())
    } else {
      dispatch(openEventDetails(eventId))
    }
  },
)

export const openGuestList = createAsyncAction<void, string>(
  'OPEN_GUEST_LIST',
  async (eventId, {getState, dispatch}) => {
    let path
    if (eventId) {
      const event = getEventById(getState(), eventId) ?? getActiveEvent(getState())
      path = isInitiallyTicketed(event) ? NEW_DASHBOARD_PATHS.ORDERS(eventId) : NEW_DASHBOARD_PATHS.RSVP_GUESTS(eventId)
    } else {
      path = NEW_DASHBOARD_PATHS.MY_EVENTS
    }

    dispatch(openDashboard(path))
  },
)

const openDashboard = createAsyncAction<void, string>('OPEN_DASHBOARD', async (path, {extra: {Wix, bi, editorSDK}}) => {
  bi.report(boLoadingStart101({instance_id: Wix.Utils.getInstanceId()}))

  await editorSDK.editor.openDashboardPanel(EVENTS_APP_ID, {
    url: `/events${path}`,
    closeOtherPanels: false,
  })

  Wix.Settings.refreshApp()
  window.location.reload()
  Wix.Settings.triggerSettingsUpdatedEvent({type: SettingsUpdatedActionType.RELOAD_EVENTS})
})

interface OpenBillingPageParams {
  multiEventWidget: boolean
  calendar: boolean
  recurringFilter: number
}

export const openBillingPage = createAsyncAction<void, OpenBillingPageParams>(
  'OPEN_BILLING_PAGE',
  async ({multiEventWidget, calendar, recurringFilter}, {getState, extra: {Wix, bi}}) => {
    bi.report(
      upgradeClick({
        instance_id: Wix.Utils.getInstanceId(),
        origin: 'WIDGET_SETTINGS',
        ...getGeneralFields({state: getState(), multiEventWidget, calendar, recurringFilter}),
      }),
    )
    const msid = Wix.Utils.getInstanceValue('metaSiteId')
    window.open(getBillingUrl(msid, 'settings'), '_blank')
  },
)

interface OpenPagesModalParams {
  t: TFunction
  origin: PAGES_MODAL_ORIGIN
}

export const openPagesModal = createAsyncAction<void, OpenPagesModalParams>(
  'OPEN_PAGES_MODAL',
  async ({t, origin}, {getState, extra: {Wix, bi}}) => {
    const state = getState()
    const scheduleInstalled = state.installedApps.schedule
    const title = t('pagesModal_title')

    bi.report(
      pagesModalOpened({
        instance_id: Wix.Utils.getInstanceId(),
        origin,
      }),
    )

    Wix.Settings.openModal(PAGES_MODAL_URL, 744, scheduleInstalled ? 358 : 254, title)
  },
)
