import {ExperimentsBag} from '@wix/yoshi-flow-editor'
import {ExperimentNames} from '@wix/wix-events-commons-statics'
import {CommonState} from '../types/state'

const isExperimentEnabled = (experiments: ExperimentsBag, name: string) => experiments[name] === 'true'

export const isSelectEventsManuallyEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.SelectEventsManually)

export const isHeadlessSupportEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.EventsHeadless)

export const isEventDetailsMobileUpdateEnabled = (state: CommonState) =>
  isExperimentEnabled(state.experiments, ExperimentNames.EventDetailsMobileUpdate)
