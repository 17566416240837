import {Dispatch} from 'redux'
import {CommonStoreExtraArgs, GetCommonState} from '../types/state'
import {getLanguage, getLocale, getTimezone, isEditor, isMobile, isPreview, isSite} from '../utils/wix-code-api'
import {isHeadlessSupportEnabled} from '../selectors/experiments'

export const SET_BASE_ENVIRONMENT = 'SET_BASE_ENVIRONMENT'
export const SET_FORM_FACTOR = 'SET_FORM_FACTOR'
const HEADLESS_QUERY_PARAM = 'headlessExternalUrls'

export const setBaseEnvironment =
  () =>
  (dispatch: Dispatch, getState: GetCommonState, {wixCodeApi}: CommonStoreExtraArgs) => {
    const state = getState()

    const headless = isHeadlessSupportEnabled(state)
      ? Boolean(wixCodeApi.location.query?.[HEADLESS_QUERY_PARAM])
      : false
    // query.origin isn't the final origin URL. It will have to be processed by @wix/wix-to-headless-redirect-client, once they implement it.
    const headlessOrigin = isHeadlessSupportEnabled(state) ? wixCodeApi.location.query?.origin : undefined
    const homePageUrl = headless ? headlessOrigin : wixCodeApi.location.baseUrl

    dispatch({
      type: SET_BASE_ENVIRONMENT,
      payload: {
        locale: getLocale(wixCodeApi) || state.siteSettings.locale,
        language: getLanguage(wixCodeApi),
        timezone: getTimezone(wixCodeApi),
        mobile: isMobile(wixCodeApi),
        editor: isEditor(wixCodeApi),
        preview: isPreview(wixCodeApi),
        site: isSite(wixCodeApi),
        homePageUrl,
        headless,
      },
    })
  }

export const setFormFactor = (formFactor: string) => ({
  type: SET_FORM_FACTOR,
  payload: formFactor === 'Mobile',
})
